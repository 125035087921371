<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-66 md-xsmall-size-80 mx-auto">
      <md-card
        class="md-theme-default md-card-wizard active"
        style="min-height: unset;"
      >
        <ValidationObserver ref="form">
          <form @submit.prevent="validate">
            <md-card-header class="md-card-header text-center">
              <h3 class="title">
                Holidays Form
              </h3>
              <h5 class="category">
                Complete the form.
              </h5>
              <b>Please take in consideration creating new holidays
                days might take a few seconds to process.</b>
            </md-card-header>

            <md-card-content>
              <div class="md-layout">
                <div class="md-layout-item md-size-100">
                  <form-date
                    v-model="model.date"
                    name="date"
                    label="Date"
                    rules="required"
                  />
                </div>
                <div class="md-layout-item md-size-100">
                  <form-text
                    v-model="model.description"
                    name="description"
                    label="Description"
                    icon="description"
                    rules="required"
                  />
                </div>
              </div>
            </md-card-content>
            <md-card-actions md-alignment="space-between">
              <md-button
                class="btn-previous"
                @click.native="$router.push({name:'HolidayDays'})"
              >
                Cancel
              </md-button>
              <md-button
                class="md-success"
                @click="saveHoliday"
              >
                Save
              </md-button>
            </md-card-actions>
          </form>
        </ValidationObserver>
      </md-card>
    </div>
  </div>
</template>

<script>
import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { FormDate, FormText } from '@/components/Inputs';

extend('required', required);

export default {
  components: {
    FormDate,
    FormText,
  },
  data() {
    return {
      model: {
        date: null,
        description: null,
      },
      loading: false,
    };
  },
  mounted() {
    if (this.$route.name === 'EditHolidayDay') {
      this.getHoliday();
    }
  },
  methods: {
    saveHoliday() {
      this.$refs.form.validate().then((res) => {
        if (res) {
          const { id } = this.$route.params;
          // axios call to backend.

          if (id == null) {
            this.loading = true;
            this.request(`${this.$API.HOLIDAY_DAYS}`, 'post', this.model, () => {
              this.fireSuccess('New Holiday has been created!');
              this.cleanForm();
              this.onReturn();
            });
          } else {
            this.loading = true;
            this.request(`${this.$API.HOLIDAY_DAYS}${id}`, 'put', this.model, () => {
              this.fireSuccess('Holiday has been updated!');
              this.cleanForm();
              this.onReturn();
            });
          }
        }
      });
      this.loading = false;
    },
    getHoliday() {
      this.request(`${this.$API.HOLIDAY_DAYS}${this.$route.params.id}`, 'get', null, ({ data }) => {
        this.model = data;
      });
    },
    cleanForm() {
      this.model = {
        date: null,
        description: null,
      };
    },
    onReturn() {
      this.$router.push({ name: 'HolidayDays' });
    },
  },
};
</script>
